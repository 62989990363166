export const _userList = {
    //筛选
    search: [
        {
            type: 'el-input',
            label: 'id：',
            value: 'id',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: '手机号：',
            value: 'mobile',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: 'unionid',
            value: 'unionid',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: '微信昵称',
            value: 'nickname',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        }
    ],
    //列表
    table: [
        {
            type: 'index',
            label: '序号',
            width: '100'
        },
        {
            prop: 'id',
            label: 'id',
            width: '100'
        },
        {
            prop: 'mobile',
            label: '手机号',
            width: '200'
        },
        {
            prop: 'inputMobile',
            label: '自输入手机号',
            width: '200'
        },

        {
            prop: 'unionid',
            label: 'unionid',
            width: '300'
        },
        {
            prop: 'nickname',
            label: '微信昵称',
            width: '300'
        },
        {
            label: '微信头像',
            self: {
                body: 'headImg'
            },
            width: '100'
        },
        {
            prop: '_createTime',
            label: '时间'
        }
    ]
};
