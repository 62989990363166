import Request from "../Request";

export default class UserList {
    public static baseUrl = '/core/api/v1/admin/b/user';

    public static getUserList(params: any) {
        return Request({
            url: `${this.baseUrl}/page`,
            method: "get",
            params
        });
    }


}
