
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import API_UserList from '@/utils/API/user/UserList';
import {_userList} from "@/utils/pageConfig/user/_userList";

@Component({
  components: {

  },
})
export default class UserList extends Vue {
  /*表格相关*/
  pageConfig: any = _userList;
  tableData: any = [];
  total: number = 0;
  params: any = {
    current: 1,
    size: 20,
  };

  mounted() {
    this.getTableData();
  }

  /*获取表格数据*/
  async getTableData() {
    let res: any = await API_UserList.getUserList(this.params);
    this.tableData =
        (res.records &&
            res.records.length > 0 &&
            res.records.map((e: any) => {
              e._createTime = Foundation.dateFormat(e.createTime);
              return e;
            })) ||
        [];
    this.total = res.total;
  }

  /*底部分页切换*/
  currentChange(pageNumber: number) {
    this.params.current = pageNumber;
    this.getTableData();
  }

  /*底部分页条数切换*/
  sizeChange(sizeNumber: number) {
    this.params.size = sizeNumber;
    this.getTableData();
  }

  /*查询*/
  handleSearch() {
    let query: any = Foundation.resetParams((this.$refs.searchForm as any).form, true);
    this.params = {
      current: 1,
      size: 20,
      ...query,
    };
    this.getTableData();
  }

}
